<template>
  <div>
    
    <div class="pswp" ref="pswp" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="pswp__bg"></div>
      <div class="pswp__scroll-wrap">
        <div class="pswp__container">
          <div class="pswp__item"></div>
          <div class="pswp__item"></div>
          <div class="pswp__item"></div>
        </div>
                
        <div class="pswp__ui pswp__ui--hidden">
          
          <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
            <div class="pswp__share-tooltip"></div>
          </div>
          <div v-if="!$root.isMobile && items.length > 1" class="carousel__nav-icon nav-icon--left">
            <Button 
              @click="goPrevItem"
              variant="clear"
              view="light"
              shape="circle"
              size="lg"
            >
              <slot name="before">
                <span class="rr-button__icon rr-button__icon_auto">
                  <base-icon width="47" height="47" viewBox="0 0 48 48">
                    <icon-arrow-left />
                  </base-icon>
                </span>
              </slot>
            </Button>
          </div>
          <div v-if="!$root.isMobile && items.length > 1" class="carousel__nav-icon nav-icon--right">
            <Button 
              @click="goNextItem"
              variant="clear"
              view="light"
              shape="circle"
              size="lg"
            >
              <slot name="before">
                <span class="rr-button__icon rr-button__icon_auto">
                  <base-icon width="47" height="47" viewBox="0 0 48 48">
                    <icon-arrow-right />
                  </base-icon>
                </span>
              </slot>
            </Button>
          </div>

        </div>
      </div>
    </div>


    <div v-if="isOpened" class="container__header" :class="!$root.isMobile ? 'container__header--large' : ''">
      <Button 
        @click="() => { gallery.close() }" 
        variant="clear"
        view="light"
        shape="circle"
        class="header__button-cancel" 
        :class="$root.isMobile ? '' : 'header__button-cancel--large'"
      >
        <slot name="before">
          <span class="rr-button__icon rr-button__icon_auto">
            <base-icon v-if="theme === ''" width="20" height="20" viewBox="0 0 24 24">
              <icon-close2 fill="var(--color-white)" opacity="1" />
            </base-icon>
            <base-icon v-if="theme !== ''" width="20" height="20" viewBox="0 0 20 20">
              <icon-close />
            </base-icon>
          </span>
        </slot>
      </Button>
      
      <div 
        class="header__qty"
        :class="$root.isMobile ? '' : 'header__qty--large'"
      >
        {{ (index + 1) + " / " + items.length }}
      </div>
    </div>


    <div v-if="isOpened" class="container__background">
      <div class="background__loader">
        <Loader size="18" fill="var(--color-primary)" />
      </div>
    </div>


    <div v-if="isOpened && noCheckbox == false" class="container__footer text-center" :class="!$root.isMobile ? 'container__footer--large' : ''">
      <div class="footer__actions" :class="showItsMe && isItsmePhoto(index) ? 'footer__actions--itsme' : ''">
        <div
          class="d-flex"
          :class="showItsMe && $props.productTypes.length === 0 ? 'justify-center' : 'justify-space-between align-end'"
          style="width: 100%;"
        >
          <div
            v-if="$props.productTypes.length > 0"
            style="position: relative;"
            :style="showItsMe && !$root.isMobile && !$root.isTablet ? 'width: calc(100% / 3); text-align: left;' : ''"
          >
            <Button 
              @click="onOption(0)"
              variant="secondary"
              view="light"
              size="lg"
              iconLeft="photo-in-frame"
              :text="$t('photoPreview/frame')"
              class="actions__button"
              mobile
            ></Button>
          </div>
          <div v-else></div>

          <div v-if="enableDownload" style="color: white;">
            <Button 
              @click="onDownload(index)"
              variant="primary"
              size="lg"
              iconLeft="download"
              :text="$t('photoPreview/download')"
              class="actions__button"
              :style="$root.isMobile ? 'height: 56px;' : ''"
            ></Button>
          </div>

          <div v-if="!enableDownload && !showItsMe" :style="{ 'color': getChecked(1) ? 'var(--color-success)' : 'white' }">
            <Button 
              @click="!isPhotoAddingToCart(index) ? onAddToCart() : null"
              :variant="getChecked(1) ? 'ghost' : 'primary'"
              :view="getChecked(1) ? 'success' : ''"
              size="lg"
              class="actions__button"
              :class="getChecked(1) ? 'actions__button--in-cart' : ''"
              :style="$root.isMobile ? 'height: 56px;' : ''"
            >
              <slot name="before" v-if="isPhotoAddingToCart(index) || !getChecked(1)">
                <span v-if="!isPhotoAddingToCart(index)" class="rr-button__icon rr-button__icon_auto">
                  <base-icon v-if="theme === ''" width="20" height="20" viewBox="0 0 20 20">
                    <icon-shopping-cart2 fill="currentColor" />
                  </base-icon>
                  <base-icon v-if="theme !== ''" width="20" height="20" viewBox="0 0 24 24">
                    <icon-shopping-cart fill="currentColor" opacity="1" />
                  </base-icon>
                </span>
                <Loader v-if="isPhotoAddingToCart(index)" size="18" fill="rgba(255, 255, 255, 0.87)" />
              </slot>
              <slot v-if="!isPhotoAddingToCart(index)">
                <span class="rr-button__text" v-if="!getChecked(1)">{{ formatAmount($props.amount) }}</span>
                <span class="rr-button__text" v-if="getChecked(1)">{{ $t('photoPreview/inCart') }}</span>
              </slot>
            </Button>
          </div>

          <div
            v-if="showItsMe"
            :style="showItsMe && $props.productTypes.length > 0 && !$root.isMobile && !$root.isTablet ? 'width: calc(100% / 3);' : ''"
          >
            <div
              v-if="isItsmePhoto(index)"
              class="itsme__label"
            >
              <Icon name="selfie" size="s" />
              <span>{{ $t('findMore/yourPhoto') }}</span>
            </div>
            <Button 
              @click="onItsMe(index)"
              variant="primary"
              size="lg"
              :text="!isItsmePhoto(index) ? $t('findMore/thisIsMe') : $t('findMore/thisIsNotMe')"
              :iconLeft="!isItsmePhoto(index) ? 'check-bold' : 'denied'"
              class="itsme__button"
              mobile
            ></Button>
          </div>

          <div
            v-if="showItsMe && $props.productTypes.length > 0 && !$root.isMobile && !$root.isTablet"
            style="width: calc(100% / 3);"
          ></div>

        </div>
      </div>
    </div>

  </div>
</template>


<script lang="js">
import { mapGetters } from 'vuex';
import Loader from '@/components/loader.vue';
import BaseIcon from '@/components/base-icon/base-icon.vue';
import IconClose from '@/components/icons/icon-close.vue';
import IconClose2 from '@/components/icons/icon-close2.vue';
import IconArrowLeft from '@/components/icons/icon-arrow-left.vue';
import IconArrowRight from '@/components/icons/icon-arrow-right.vue';
import IconShoppingCart from '@/components/icons/icon-shopping-cart.vue';
import IconShoppingCart2 from '@/components/icons/icon-shopping-cart2.vue';
import Common from '@/plugins/common';

export default {
  name: 'PhotoPreview',
  props: {
    photos: Array,
    checked: Array,
    noCheckbox: Boolean,
    showItsMe: Boolean,
    enableDownload: Boolean,
    addingToCart: Array,
    currentIndex: Number,
    productTypes: Array,
    amount: Number,
    isOpened: Boolean,
    isPaginationEnabled: Boolean,
    isPreorder: Boolean,
    productIndex: Number,
    key_: String,
  },
  components: {
    Loader,
    BaseIcon,
    IconClose,
    IconClose2,
    IconArrowLeft,
    IconArrowRight,
    IconShoppingCart,
    IconShoppingCart2,
  },
  data() {
    return {
      checked_: [],
      index_: 0,
      gallery: {},
      isPageChanged: false,
      itsmePhotos: [],
    };
  },
  computed: {
    ...mapGetters('SettingsState', ['theme']),
    items() {
      return this.$props.photos;
    },
    localChecked() {
      return this.checked_;
    },
    index: {
      get: function() {
        return this.index_;
      },
      set: function(value) {
        this.index_ = value;
      },
    },
    key() {
      return this.$props.key_;
    },
    photosPerPage() {
      if (this.$root.isMobile || this.$root.isTablet) return 12;
      return 50;
    },
  },
  watch: {
    key() {
      this.open();
    },
    isOpened() {
      this.open();
    },
    checked() {
      this.checked_ = this.$props.checked;
    },
    currentIndex() {
      this.index = this.$props.currentIndex;
    },
  },
  methods: {
    open() {
      if (this.$props.isOpened) {
        const pswpElement = this.$refs.pswp;
        const items = [];

        for (let i = 0; i < this.items.length; i++) {
          const data = this.items[i];
          if (!this.$props.isPreorder) {
            if (data !== undefined) {
              items.push({
                src: this.$props.enableDownload ? data.resources.main : data.resources.watermark,
                w: data.vertical ? 666 : 1000,
                h: data.vertical ? 1000 : 666,
              });
            } else {
              items.push({
                html: '<div> </div>',
              });
            }
          } else {
            items.push({
              src: this.$props.enableDownload ? data.photo.resources.main : data.photo.resources.watermark,
              w: data.photo.vertical ? 666 : 1000,
              h: data.photo.vertical ? 1000 : 666,
            });
          }
        }

        const topOffset = this.$root.isMobile ? 56 : 64;
        const bottomOffset = this.$props.noCheckbox ? 56 : (this.$root.isMobile ? 80 : 88);

        const options = {
          barsSize: {
            top: topOffset,
            bottom: bottomOffset,
          },
          index: this.$props.currentIndex,
          pinchToClose: false,
          closeOnScroll: false,
          clickToCloseNonZoomable: false,
          closeOnVerticalDrag: false,
          history: false,
          showAnimationDuration: 0,
          hideAnimationDuration: 0,
          showHideOpacity: true,
          closeEl: false,
          captionEl: false,
          fullscreenEl: false,
          zoomEl: false,
          shareEl: false,
          counterEl: false,
          arrowEl: false,
          preloaderEl: false,
        };
        
        this.gallery = new window.PhotoSwipe(pswpElement, window.PhotoSwipeUI_Default, items, options);
        this.gallery.listen('close', this.onCancel);
        this.gallery.listen('afterChange', () => {
          const prevPage = this.getPageNumber(this.index_);
          this.index_ = this.gallery.getCurrentIndex();
          if (this.$props.isPaginationEnabled && this.getPageNumber(this.index_) != prevPage) {
            this.isPageChanged = true;
            this.gallery.close();
            this.$emit('pagechanged', this.index_);
          }
        });
        this.gallery.init();

        if (this.$props.productIndex !== undefined) {
          window.location.hash = `${this.$props.productIndex}:${this.$props.currentIndex}`;
        }

      } else {
        this.gallery.close();
        
        if (this.$props.productIndex !== undefined) {
          window.location.hash = '';
        }
      }

      this.setItsmePhotos();
    },
    getChecked(type) {
      if (!this.items[this.index]) return false;

      if (type == 1) {
        return this.localChecked.includes(this.items[this.index].photoId);
      } else {
        return !this.localChecked.includes(this.items[this.index].photoId);
      }
    },
    formatAmount(amount) {
      return Common.formatAmount(amount, false);
    },
    getPageNumber(index) {
      return index == 0 ? 0 : Math.floor(index / this.photosPerPage);
    },
    onOption(index) {
      this.$emit('optionclick', { photoId: this.items[this.index].photoId, productIndex: index });
    },
    onAddToCart() {
      if (this.getChecked(1) == true) {
        this.$router.push({ name: 'cart' });
        return;
      }
      this.$emit('addtocart', this.index);
    },
    onDownload(index) {
      this.$emit('download', index);
    },
    onCancel() {
      if (!this.isPageChanged) {
        this.$emit('cancelled');
        this.index = this.$props.currentIndex;
      } else {
        this.isPageChanged = false;
      }
    },
    goPrevItem() {
      this.gallery.prev();
    },
    goNextItem() {
      this.gallery.next();
    },
    onItsMe(index) {
      this.$emit('itsme', this.$props.photos[index]);
      this.itsmePhotos[index].itsme = !this.itsmePhotos[index].itsme;
    },
    setItsmePhotos() {
      this.itsmePhotos = [...this.$props.photos];
    },
    isItsmePhoto(index) {
      return this.itsmePhotos[index].itsme === true;
    },
    isPhotoAddingToCart(index) {
      const photoId = this.$props.photos[index] ? this.$props.photos[index].photoId || '' : '';
      const item = this.$props.addingToCart.find((i) => i.id === photoId);
      if (item) {
        return item.adding === true;
      }
      return false;
    }, 
  },
  mounted() {
    this.checked_ = this.$props.checked;
    this.index = this.$props.currentIndex;
  },
};
</script>


<style lang="scss" scoped>
  @import "./photo-preview";
</style>
